import { Box, SxProps } from '@mui/material';
import { FieldInputProps } from 'formik';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Select } from 'src/components/common';
import { SelectOption } from 'src/components/common/Select';
import { FinancialProject, useGetProfileProjects } from 'src/queries';
import { isString } from 'src/utils';
import { isEmpty } from 'src/validations';
import { getFinancialProjectNumberOptions } from './helpers';
import { ORDER } from 'src/appConfig/constants';
import { ProjectColumnOrder } from '../types';
import './styles.scss';

const SearchProjectNumber: FC<Props> = ({
  errorMessage,
  fieldProps,
  disabled = false,
  isClearable = true,
  sx,
  label = '',
  maxLength,
  required = false,
  menuIsOpen,
  isSearchableTerminatedProject = false,
  isSearchableInactiveProject = false,
  // order = ORDER.ASC,
  // sort = ProjectColumnOrder.NUMBER,
  order,
  sort,
  setFieldTouched,
  onChange,
  onInputChange,
}) => {
  const { value, name } = fieldProps;
  const selectedProjectNumber = typeof value === 'string' ? value : value?.number;
  const [inputValue, setInputValue] = useState<string>(selectedProjectNumber);

  //always keep search input
  //show data of select box is state of search input (not value of select)
  useEffect(() => {
    setInputValue(selectedProjectNumber);
  }, [selectedProjectNumber]);

  const {
    profileProjects,
    setParams: setParamsSearchProject,
    isLoading: isLoadingSearchProjects,
  } = useGetProfileProjects();

  const filteredProjectNumberOptions = useMemo(() => {
    if (isLoadingSearchProjects || !inputValue) {
      return [];
    }

    return getFinancialProjectNumberOptions({
      projects: profileProjects,
    });
  }, [inputValue, isLoadingSearchProjects, profileProjects]);

  // useEffect(() => {
  //   if (selectedProjectNumber) {
  //     setParamsSearchProject({
  //       search: selectedProjectNumber,
  //       includeTerminated: !isEmpty(selectedProjectNumber) && isSearchableTerminatedProject,
  //       includeInactive: !isEmpty(selectedProjectNumber) && isSearchableInactiveProject,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedProjectNumber]);

  // Debouncing search projects inputs
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchProjectsInput = useCallback(
    debounce((value: string) => {
      setParamsSearchProject({
        search: value,
        includeTerminated: !isEmpty(value) && isSearchableTerminatedProject,
        includeInactive: !isEmpty(value) && isSearchableInactiveProject,
        sort,
        order,
      });
    }, 300),
    []
  );

  // useEffect(() => {
  //   debounceSearchProjectsInput(inputValue);
  // }, [debounceSearchProjectsInput, inputValue]);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    debounceSearchProjectsInput(value);
  };

  return (
    <Box sx={sx}>
      {disabled ? (
        <Input
          {...fieldProps}
          label={label}
          placeholder="Search"
          value={isString(value) ? value : value?.number || ''}
          disabled
          errorMessage={errorMessage}
        />
      ) : (
        <Select
          {...fieldProps}
          value={fieldProps.value ?? ''}
          errorMessage={errorMessage}
          onBlur={setFieldTouched}
          label={label}
          placeholder={'Search'}
          options={filteredProjectNumberOptions}
          isLoading={isLoadingSearchProjects}
          inputValue={inputValue || ''}
          onInputChange={(val, meta) => {
            if (['input-change', 'input-select'].includes(meta.action)) {
              handleInputChange(val);
              onChange && onChange(name, val);
              onInputChange && onInputChange(name, val);
            }
          }}
          required={required}
          defaultInputValue={selectedProjectNumber} //first mounted with data get from PO response
          getOptionLabel={(option: SelectOption<FinancialProject>) => {
            return option.value?.number;
          }}
          customSelectedOptionValue={
            filteredProjectNumberOptions.find(
              (option: SelectOption<FinancialProject>) => option.value?.number === value
            ) || null
          }
          filterOption={(_option, _inputValue) => {
            return true; //ignore default filter option by label
          }}
          menuStyle={{
            width: '760px',
          }}
          className={'cmp-select__input'}
          hideSearchIcon
          isClearable={isClearable}
          onChange={onChange}
          optionWithSubLabel
          hideDropdownIndicator
          searchMaxLength={maxLength}
          menuIsOpen={menuIsOpen}
        />
      )}
    </Box>
  );
};

type Props = {
  errorMessage: string;
  fieldProps: FieldInputProps<FinancialProject | string>;
  disabled?: boolean;
  sx?: SxProps;
  isClearable?: boolean;
  required?: boolean;
  label?: string;
  maxLength?: number;
  menuIsOpen?: boolean;
  isSearchableTerminatedProject?: boolean;
  isSearchableInactiveProject?: boolean;
  order?: ORDER;
  sort?: ProjectColumnOrder;
  setFieldTouched?: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
  onInputChange?: (name, value) => void;
  onChange: (name, value) => void;
};

export default SearchProjectNumber;

import { Box, TextField, TextFieldProps } from '@mui/material';
import { MouseEventHandler, RefObject, useRef } from 'react';
import { COLOR_CODE } from 'src/appConfig/constants';
import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import { Icon } from '..';
import Element from '../Element';
import View from '../View';
import './styles.scss';

const InputAutosize: React.FC<InputAutosizeProps> = ({
  errorMessage,
  label,
  className,
  containerClassName,
  inputRef = null,
  iconName = '',
  resize = 'none',
  required,
  disabled,
  isUncontrolledInput = false,
  maxLength,
  footer,
  onIconClick,
  value,
  ...props
}) => {
  const id = useRef<string>(`input-${getRandomId()}`);
  const uncontrolledInputRef = useRef<HTMLTextAreaElement>(null);

  if (isUncontrolledInput && uncontrolledInputRef.current) {
    uncontrolledInputRef.current.value = props.defaultValue?.toString();
  }

  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={containerClassName}
      required={required}
    >
      <View>
        <TextField
          id={id.current}
          inputRef={inputRef ?? (isUncontrolledInput ? uncontrolledInputRef : undefined)}
          disabled={disabled}
          multiline
          sx={{
            ...props.sx,
            background: COLOR_CODE.WHITE,

            '& fieldset': {
              top: 0,
            },
            '& legend': {
              display: 'none',
            },
            '& .MuiInputBase-root': {
              padding: '6px 14px',

              '&.Mui-focused fieldset': {
                borderColor: !isEmpty(errorMessage) ? COLOR_CODE.DANGER : COLOR_CODE.PRIMARY,
                borderWidth: '1px',
              },

              '&.Mui-disabled': {
                backgroundColor: COLOR_CODE.BG_DISABLED,
              },
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'black',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: COLOR_CODE.DEFAULT_BORDER,
              borderColor: !isEmpty(errorMessage) && COLOR_CODE.DANGER,
              borderRadius: COLOR_CODE.DEFAULT_BORDER_RADIUS,
            },
          }}
          inputProps={{
            ...props.inputProps,
            maxLength,
            sx: {
              ...props.inputProps?.sx,
              resize: resize,
            },
          }}
          value={!isUncontrolledInput ? value ?? '' : value}
          {...props}
        />
        {iconName && (
          <Icon name={iconName} className="cmp-input-autosize__icon" onClick={onIconClick} />
        )}
      </View>
      {footer && <Box mt={1}>{footer}</Box>}
    </Element>
  );
};

export type InputAutosizeProps = TextFieldProps & {
  errorMessage?: string;
  containerClassName?: string;
  inputRef?: RefObject<HTMLTextAreaElement>;
  iconName?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  resize?: 'horizontal' | 'vertical' | 'bold' | 'none';
  isUncontrolledInput?: boolean;
  maxLength?: number;
  footer?: React.ReactNode;
  onIconClick?: MouseEventHandler<HTMLElement>;
};

export default InputAutosize;
